import React, { useEffect } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend, ArcElement);

function Results({ formData, onNext }) {
  const { faucets, showers, toilets, monthlyConsumption, city,savingsPercentage } = formData;

  const cityRates = {
    Casablanca: 7,
    Rabat: 6.5,
    Marrakech: 6,
    Tanger: 5.5,
    Fès: 5,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Calculate annual savings and cost using the provided utility functions
  const annualSavingsM3 = calculateAnnualSavings({ faucets, showers, toilets, monthlyConsumption });
  const annualSavingsDirhams = calculateSavingsDirhams(annualSavingsM3, city, cityRates);

  const annualConsumption = monthlyConsumption * 12;
  const annualCost = annualConsumption * (cityRates[city] || 5);
  const remainingConsumption = (annualConsumption -annualSavingsM3 ).toFixed(2);

  // Data for the bar chart
  const barChartData = {
    labels: ['Consommation actuelle (m³)', 'Consommation économisée (m³)'],
    datasets: [
      {
        label: 'Consommation en m³',
        data: [annualConsumption,  annualSavingsM3],
        backgroundColor: ['#36A2EB',  '#4BC0C0'],
      },
    ],
  };
  // Function to calculate annual savings based on the given formula
function calculateAnnualSavings({ faucets, showers, toilets, monthlyConsumption }) {
  // Constants for savings percentages
  const faucetSavings = 0.6 ; // 60% savings for faucets with 30% usage
  const showerSavings = 0.55 ; // 55% savings for showers with 45% usage
  const toiletSavings = 0.5 ; // 50% savings for toilets with 25% usage

  // Calculate the total number of water points  
 const totalPoints = Number(faucets) + Number(showers) + Number(toilets);

  // Calculate monthly consumption contributions for each type  
  const faucetConsumption = (monthlyConsumption * (faucets / totalPoints));
  console.log(faucetConsumption);
  console.log("monthly " ,totalPoints);
  const showerConsumption = (monthlyConsumption * (showers / totalPoints));
  console.log(showerConsumption);
  const toiletConsumption = (monthlyConsumption * (toilets / totalPoints));
  console.log(toiletConsumption);
  // Formula for annual water savings in cubic meters
  const annualSavings = 
    (faucetConsumption * faucetSavings + showerConsumption * showerSavings + toiletConsumption * toiletSavings) * 
    12;

  return annualSavings.toFixed(2); // Return result rounded to two decimal places
}

// Function to calculate savings in dirhams (DHS)
function calculateSavingsDirhams(annualSavings, city, cityRates) {
  const cityRate = cityRates[city] || 5; // Default rate if city not found
  return (annualSavings * cityRate).toFixed(2); // Return result rounded to two decimal places
}




  // Data for the pie chart (Proportion of Savings vs Remaining Consumption)
  const pieChartData = {
    labels: ['Consommation restante', 'Consommation économisée'],
    datasets: [
      {
        data: [remainingConsumption, annualSavingsM3],
        backgroundColor: ['#36A2EB', '#4BC0C0'],
        hoverBackgroundColor: ['#36A2EB', '#4BC0C0'],
      },
    ],
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-semibold mb-4">Résultats</h2>
      
      <div className="mb-4">
        <h3 className="text-lg font-semibold">Taux économie antérieur :</h3>
        <p>{savingsPercentage} m³</p>
      </div>
      <div className="mb-4">
        <h3 className="text-lg font-semibold">Consommation annuelle en m³ :</h3>
        <p>{annualConsumption} m³</p>
      </div>

      <div className="mb-4">
        <h3 className="text-lg font-semibold">Coût annuel estimé en dirhams :</h3>
        <p>{annualCost.toFixed(2)} DHS</p>
      </div>

      <div className="mb-4">
        <h3 className="text-lg font-semibold">Économies potentielles avec H2Oasis :</h3>
        <p>Économie en m³ par an : {annualSavingsM3} m³</p>
        <p>Économie en dirhams par an : {annualSavingsDirhams} DHS</p>
      </div>

      {/* Container for side-by-side charts */}
      <div className="mt-6 flex flex-col md:flex-row justify-center items-center gap-4">
        {/* Bar Chart */}
        <div className="chart-container" style={{ maxWidth: '500px', width: '100%' }}>
          <h3 className="text-lg font-semibold text-center mb-4">Comparaison de la consommation</h3>
          <Bar
            data={barChartData}
            options={{
              maintainAspectRatio: true,
              responsive: true,
              plugins: {
                legend: { display: true, position: 'bottom' },
              },
              scales: { y: { beginAtZero: true } },
            }}
          />
        </div>

        {/* Pie Chart */}
        <div className="chart-container" style={{ maxWidth: '300px', width: '100%' }}>
          <h3 className="text-lg font-semibold text-center mb-4">Proportion de l'économie potentielle</h3>
          <Pie
            data={pieChartData}
            options={{
              maintainAspectRatio: true,
              responsive: true,
              plugins: {
                legend: { display: true, position: 'bottom' },
              },
            }}
          />
        </div>
      </div>

      {/* Large Button for Proposition */}
      <div className="mt-10">
        <button
          onClick={onNext}
          className="bg-green-500 text-white text-xl font-bold py-4 px-8 rounded-lg shadow-lg hover:bg-green-600 transition-all w-full"
        >
          Voir la Proposition
        </button>
      </div>
    </div>
  );
}

export default Results;
