import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';

function Proposition({ formData }) {
  const { faucets, showers, toilets } = formData;

  // State for CTA form
  const [showForm, setShowForm] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [userDetails, setUserDetails] = useState({ name: '', email: '', phone: '', address: '' });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
  };

  // Handle form submission
  // Inside the Proposition component
const handleFormSubmit = async (e) => {
  e.preventDefault();
  
  try {
    // Send formData and userDetails to the backend
    await axios.post('https://h2oasis-backend-1.onrender.com/api/propositions', {
      formData,
      userDetails,
    });
    
    setFormSubmitted(true); // Show popup after form submission
    setShowForm(false); // Hide the form
  } catch (error) {
    console.error('Error sending proposition data:', error);
    alert("Une erreur s'est produite lors de l'envoi des données. Veuillez réessayer.");
  }
};

  const finalizeSimulation = () => {
        setFormSubmitted(false); // Reset the form submission state
        window.location.href = '/'; // Redirect to the home page
}

  return (
    <motion.div
      className="p-8 bg-white rounded-lg shadow-lg"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
    >
      <h2 className="text-3xl font-bold mb-6 text-center text-green-500">Proposition de Solutions H2Oasis</h2>

      <p className="text-lg mb-4">
        Pour maximiser vos économies, nous vous recommandons d’installer les économiseurs H2Oasis suivants :
      </p>

      <ul className="list-disc list-inside mb-6">
        {faucets > 0 && (
          <li className="text-md">
            Économiseur pour robinet : Réduit la consommation de vos {faucets} robinets de 60%.
          </li>
        )}
        {showers > 0 && (
          <li className="text-md">
            Économiseur pour douche : Réduit la consommation de vos {showers} douches de 55%.
          </li>
        )}
        {toilets > 0 && (
          <li className="text-md">
            Économiseur pour chasses d’eau : Réduit la consommation de vos {toilets} chasses d'eau de 50%.
          </li>
        )}
      </ul>

      {/* Call to Action Button */}
      <motion.button
        className="bg-blue-500 text-white py-3 px-8 text-xl font-semibold rounded-lg shadow-lg hover:bg-blue-600 transition w-full mb-8"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => setShowForm(true)} // Show the form
      >
        Découvrez nos produits
      </motion.button>

      {/* CTA form to receive a sample */}
      {showForm && (
        <div className="bg-gray-50 p-6 rounded-lg shadow-inner">
          <h3 className="text-2xl font-semibold mb-4">Voulez-vous recevoir un échantillon ?</h3>
          <form onSubmit={handleFormSubmit}>
            <div className="mb-4">
              <label className="block mb-2">Nom</label>
              <input
                type="text"
                name="name"
                value={userDetails.name}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-lg"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Email</label>
              <input
                type="email"
                name="email"
                value={userDetails.email}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-lg"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Téléphone</label>
              <input
                type="tel"
                name="phone"
                value={userDetails.phone}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-lg"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Adresse</label>
              <input
                type="text"
                name="address"
                value={userDetails.address}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-lg"
                required
              />
            </div>
            <button
              type="submit"
              className="bg-green-500 text-white py-3 px-6 text-lg font-semibold rounded-lg shadow-lg hover:bg-green-600 transition w-full"
            >
              Envoyer
            </button>
          </form>
        </div>
      )}

      {/* Popup after form submission */}
      {formSubmitted && (
        <motion.div
          className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h3 className="text-2xl font-semibold mb-4">Merci pour votre demande !</h3>
            <p>Votre échantillon H2Oasis sera envoyé à l'adresse indiquée.</p>
            <button
              onClick={() => finalizeSimulation()}
              className="bg-blue-500 text-white py-2 px-6 rounded-lg shadow-lg mt-4 hover:bg-blue-600 transition"
            >
              Fermer
            </button>
          </div>
        </motion.div>
      )}
    </motion.div>
  );
}

export default Proposition;
